/* eslint import/first: 0 */
import { configure } from 'mobx'

// avoid using Proxy as it's not supported in IE11
configure({ useProxies: 'never' })

import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'

import 'resize-observer-polyfill'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/es/object'

import { loadConfig } from './config'

const App = React.lazy(() => import('./App/AppWrapper'))

const renderApp = async () => {
  const config = await loadConfig()
  const root = createRoot(document.getElementById('root'))
  root.render(
    <Suspense fallback={null}>
      <App config={config} />
    </Suspense>
  )
}

renderApp()
