import qs from 'qs'

import { BU_CODES, OTHER_BU } from '../constants'
// If temporary flag, add 'tmp-' prefix
// When removing temporary flag, create a branch cleaning/MEH-XXX-feature-name

export const featureOverwriteInURL = {}
export const getFeatureToggleList = () => Object.keys(window._features || {})

export const getBUsToggledOffForPolicyRenewal = () => {
  return [...BU_CODES, OTHER_BU].filter(buCode => !featureToggle(`tmp-policy-renewal-${buCode.toLowerCase()}`))
}

export const featureToggle = flagName => window._features && window._features[flagName]

export const featureOverwrite = () => {
  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  })
  if (queryParams) {
    for (const [key, value] of Object.entries(queryParams)) {
      if (window._features && window._features[key] !== undefined) {
        if (value === 'true') {
          window._features[key] = true
          featureOverwriteInURL[key] = true
        } else if (value === 'false') {
          window._features[key] = false
          featureOverwriteInURL[key] = false
        } else {
          window._features[key] = value
          featureOverwriteInURL[key] = value
        }
      }
    }
  }
}
